@import url('https://rsms.me/inter/inter.css');
/* fuck WOTC */
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  overscroll-behavior-y: none;
  font-family: 'Inter var', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #ddd;
  background: #12071f;
}

a {
  pointer-events: all;
  color: #ddd;
  text-decoration: none;
}

svg {
  fill: #ddd;
}

h1 {
  background: linear-gradient(30deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.msgBox{
  position: fixed;
  left: 0;
  top: 100px;
  padding: 15px;
  font-size: 12px;
}
.ListMain {
  position: fixed;
  left: 0;
  top: 0;
  width: 400px;
  height: 390px;
  /* margin-top: 55px; */
  margin: auto;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  bottom: 0;
  right: 0;
  padding: 15px;
  font-size: 15px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.185);
  z-index: 999;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px); 
  @media screen and (max-width: 768px) {
    top: auto;
    bottom: 70px;
  }
}
.grText{
  color: #3bd100;
  padding: 3px;
}
.titleLar{
  font-size: 5em;
}
